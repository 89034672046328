























































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from "@/api";

@Component({
    name: "couponList",
    components: {}
})

export default class extends Vue {
    // 表格数据
    tableData: any = [];
    // 加载状态
    loading = false;
    // 总条数
    total = 0
    // 搜索条件
    search: any = {
        pagination: {
            page: 0, //页码，从0开始
            pageSize: 20, //每页数量
        },
    };
    config = {
        status: {
            0: '未审核',
            1: '通过',
            2: '拒绝'
        }
    }

    activated() {
        this.searchTable();
    }

    // 搜索 search:true 重置翻页
    searchTable(search = false) {
        this.loading = true;
        if (search) {
            this.search.pagination = {
                pageSize: 20,
                page: 0,
            };
        }
        api
            .post('J27316', this.search)
            .then(res => {
                this.total = res.total;
                this.tableData = res.list;
            }).finally(() =>{
            this.loading = false;
        })
    }

    // 查看
    operateObj: any = {}
    lookVisible = false

    look(item: any) {
        this.operateObj = item;
        this.lookVisible = true;
    }

    closeLookDialog() {
        this.lookVisible = false;
    }

    // 审核
    private noVisible = false;
    private visible = false;
    private remark = "";
    examineNo(item: any) {
        this.remark = ''
        this.operateObj = item;
        this.noVisible = true;
    }

    protected closeNoPassDialog() {
        this.noVisible = false;
    }

    protected summit(e: any) {
        if (e == "no") {
            if(this.remark == '') {
                this.$message.warning('请输入拒绝原因')
                return;
            }
            this.noVisible = false;
            this.audit(2)
            return;
        }
        this.audit(1);
    }

    audit(status: any) {
        api
            .post('J27318', { status: status, id: this.operateObj.id, remark: this.remark})
            .then(res => {
                this.$message.success('操作成功')
                this.noVisible = false
                this.searchTable()
            })
    }

}
